* {
    margin: 0;
    padding: 0;
}

body, html, #root, .ant-app {
    font-family: 'Segoe UI', sans-serif;
    height: 100vh;
}

::selection {
    background-color: #2d4b00;
    color: white;
}

.ant-menu-item {
    user-select: none;
}

.ant-pro-card .ant-pro-card-body {
    padding-inline: 0 !important;
    padding-block: 0 !important;
}

.ant-pro-table-list-toolbar-container {
    padding-top: 0 !important;
}

::-webkit-scrollbar {
    width: 5px;
}

::-webkit-scrollbar-track {
    background: rgba(0, 0, 0, 0.1);
    border-radius: 5px;
}

::-webkit-scrollbar-thumb {
    background: #8aa178;
    border-radius: 5px;
}

::-webkit-scrollbar-thumb:hover {
    background: #555;
}
